import React, { useEffect, useState } from "react"

import { filterStore, mapStore, pageStore } from "state/store-zustand";
import useContextTheme from 'components/utils/useContextTheme'
import usePageLoading from 'components/utils/usePageLoading'
import useMobileDetect from 'use-mobile-detect-hook'
const detectMobile = useMobileDetect()

import Header from "components/header"
import MapLayout from 'components/map/mapLayout'
import FetchEvents from 'components/utils/fetchEvents'
import FetchPlaces from 'components/utils/fetchPlaces'

import "styles/pages.scss"
import "styles/map_page.scss"

const MapPage = props => {
  let { slug, location } = props

  const slugFromPath = location.pathname.split('/')[2]
  const placeSlug = slug || slugFromPath && slugFromPath != "" ? slugFromPath : null

  // TODO: OR like this?
  const pageLoader = usePageLoading()

  const editorialCategory = filterStore((state) => state.editorialCategory)
  const shouldFetchEvents = mapStore((state) => state.shouldFetchEvents)
  const shouldFetchPlaces = mapStore((state) => state.shouldFetchPlaces)
  const hasEditorial = editorialCategory != null

  const fetchEvents = hasEditorial == false && shouldFetchEvents
  const fetchPlaces = shouldFetchPlaces

  const {
    compact,
    embedded,
    hasParams,
    isMobile,
    placeLayout,
    setIsMobile
  } = pageStore((state) => state);


  // TODO: use zustand and reduce 1 render
  const [isClient, setClient] = useState(false)
  useEffect(() => {
    setClient(true)

    const handleResize = () => {
      const isMobileNew = detectMobile.isMobile()
      const width = typeof document !== 'undefined' ? document?.body?.clientWidth : null
      //console.log('debug handleResize ', width, isMobileNew);
      setIsMobile(isMobileNew || width < 800);
    };

    // And and clean up the event listener when the component unmounts
    window.addEventListener('resize', handleResize);
    return () => { window.removeEventListener('resize', handleResize) };

  }, [])

  const themeContext = useContextTheme()
  const { theme, themeClass } = themeContext

  const pageClass = `page map ${pageLoader} ${embedded ? 'embedded' : ''} ${compact ? 'compact' : ''} ${themeClass ? themeClass : ''}`

  // Don't SSR if there are URL params
  // TODO: Handle this with Next initial router props
  if (isClient != true && hasParams) {
    return null
  }

  const useMobileLayout = placeLayout == 'map'


  return (
    <div className={pageClass}>
      {/* Fetch data inside the place context; Instead of a hook, outside of context */}
      {fetchEvents
        ? <FetchEvents />
        : null
      }
      {fetchPlaces
        ? <FetchPlaces />
        : null
      }
      <main>
        {embedded != true
          ? <Header />
          : null
        }

        <MapLayout
          slug={placeSlug}
          showLogo={embedded ? true : false}
          hasPlace
          useMobileView={useMobileLayout}
          title='Explore the Map' />
      </main>
    </div>
  )
}

export default MapPage